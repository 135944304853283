@use "@bfl/components/theming/constants" as constants;
@use "@bfl/components/theming/utils";
@use "@bfl/components/theming/variables" as variables;
@use "src/app/trafo-overview/trafo-colors-global";
@use "src/app/trafo-overview/status-map/status-map.component.global";
@use "src/app/trafo-overview/substation-map/substation-map.component.global";

.info-window-content {
  @include utils.bfc-font-type(variables.$regular-font);
  font-size: 16px;

  @media (max-width: constants.$bfc-breakpoint-xs-max) {
    max-width: calc(100vw - 100px);
  }
  @media (min-width: constants.$bfc-breakpoint-sm-min) {
    max-width: calc(100vw * 0.7 - 120px);
  }

  .info-window-content-title {
    @include utils.bfc-font-type(variables.$bold-font);
    padding-bottom: constants.$bfc-default-spacing;
    display: inline-block;
  }
}

.legend {
  @include utils.bfc-font-type(variables.$regular-font);
  margin: constants.$bfc-default-spacing;
}

.inline-block {
  display: inline-block !important;
}

.blue-button-icon {
  color: variables.$primary-default;
  margin-right: constants.$bfc-default-spacing;
  display: inline-block;
  font-size: 18px;
}

.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  background-color: currentColor;
  vertical-align: middle;
  margin-right: calc(0.5 * constants.$bfc-default-spacing);
}

.online {
  color: trafo-colors-global.$trafo-color-online;
  .dot {
    background-color: trafo-colors-global.$trafo-color-online;
  }
}

.offline {
  color: trafo-colors-global.$trafo-color-offline;
  .dot {
    background-color: trafo-colors-global.$trafo-color-offline;
  }
}

.recently_offline {
  color: trafo-colors-global.$trafo-color-recently-offline;
  .dot {
    background-color: trafo-colors-global.$trafo-color-recently-offline;
  }
}

.status-text {
  font-size: 14px;
  line-height: 1;  // Ensures the text itself doesn't add extra height
  position: relative;
  top: 0.1em;  // Fine-tunes the position to align it perfectly with the dot
}

.mat-mdc-tab-list {
  flex-grow: 0 !important;
}