@use "sass:math";
@use "@bfl/components/theming/constants" as constants;
@use "@bfl/components/theming/utils";
@use "@bfl/components/theming/variables" as variables;

.substation-map-component {
  .legend {
    margin-top: calc(1.5 * constants.$bfc-default-spacing);
    width: 100%;

    .legend-text {
      font-size: 12px;

      &.bold {
        @include utils.bfc-font-type(variables.$bold-font);
      }
    }

    img {
      vertical-align: middle;
      margin-right: constants.$bfc-default-spacing;
    }
  }

  mat-form-field.mobile {

    .mat-form-field-underline {
      background-color: white;
    }

    mat-select .mat-select-trigger {
      .mat-select-value, .mat-select-arrow-wrapper .mat-select-arrow {
        color: white
      }
    }
  }
}