@use "sass:math";
@use "@bfl/components/theming/constants" as constants;
@use "@bfl/components/theming/variables" as variables;
@use "@bfl/components/theming/utils";

.status-map-component {
  .legend {
    margin-top: calc(1.5 * constants.$bfc-default-spacing);
    width: 100%;

    .legend-text {
      font-size: 12px;

      &.bold {
        @include utils.bfc-font-type(variables.$bold-font);
      }
    }

    img {
      vertical-align: middle;
      margin-right: constants.$bfc-default-spacing;
    }
  }

  .filter-button {
    color: white;
    @include utils.bfc-font-type(variables.$bold-font);
    cursor: pointer;
  }
}
